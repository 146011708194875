import * as ActiveStorage from '@rails/activestorage'
import Rails from '@rails/ujs'
import 'channels'
import Turbolinks from 'turbolinks'

import 'controllers'

import Glider from 'glider-js'
import GLightbox from 'glightbox'
import flatpickr from 'flatpickr'
import SmoothScroll from 'smooth-scroll'
import iziToast from 'izitoast/dist/js/iziToast'
Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.iziToast = iziToast

document.addEventListener('turbolinks:load', function () {
  const scroll = new SmoothScroll('a[href*="#"]', {
    header: '[data-scroll-header]'
  })

  flatpickr('.flatpickr', {
    altInput: true,
    altFormat: 'M j, Y',
    dateFormat: 'Y-m-d'
  })

  const lightbox = GLightbox({
    selector: '.glightbox',
    type: 'video',
    touchNavigation: true,
    autoplayVideos: true,
    onOpen: () => {
      // console.log('Lightbox opened')
    },
    plyr: {
      config: {
        vimeo: {
          poster: false // 'https://i.vimeocdn.com/video/819464545.webp?mw=3000&mh=1688&q=70'
        }
      }
    }
  })

  if (document.querySelector('.steps-glider') !== null) {
    new Glider(document.querySelector('.steps-glider'), {
      slidesToScroll: 1,
      slidesToShow: 1.1,
      draggable: true,
      dots: '.dots',
      arrows: {
        prev: '.glider-prev',
        next: '.glider-next'
      }
    })
  }
  if (document.querySelector('.questions-glider') !== null) {
    new Glider(document.querySelector('.questions-glider'), {
      slidesToScroll: 1,
      slidesToShow: 1,
      draggable: true,
      dots: '.dots',
      arrows: {
        prev: '.glider-prev',
        next: '.glider-next'
      }
    })
  }
})
