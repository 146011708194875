import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class GoalController extends Controller {
  static targets = ['container', 'open', 'close', 'header', 'body', 'form', 'view',
    'nameTextfield', 'nameValue', 'specificDays', 'daysPerWeek',
    'daysPerMonth', 'specificDate', 'frequencySelector', 'addBtn', 'deleteBtn']

  connect () {
    this.id = this.data.get('id')
    this.url = this.data.get('url')
    console.log(this.id)
    this.goalItems = document.querySelectorAll(`[data-goal-item-id='${this.id}']`)
    // StimulusReflex.register(this)
  }

  onPostSuccess (event) {
    const [data, status, xhr] = event.detail
    // console.log('onPostSuccess')
    // console.log(status)
    // console.log(xhr.response)

    if (this.nameTextfieldTarget.length > 0) {
      this.nameValueTarget.innerHTML = this.nameTextfieldTarget.value
    }

    for (var i = 0; i < this.goalItems.length; i++) {
      const goalItem = this.goalItems[i]
      // console.log(goalItem)
      goalItem.innerHTML = xhr.response
    }
    // this.endEdit()
  }

  toggleForm () {
    if (this.bodyTarget.classList.contains('hidden')) {
      this.openForm()
    } else {
      this.closeForm()
    }
  }

  openForm () {
    this.bodyTarget.classList.remove('hidden')
    this.openTarget.classList.add('hidden')
    this.closeTarget.classList.remove('hidden')
    this.headerTarget.classList.remove('bg-gray-100')
    this.headerTarget.classList.add('bg-blue-100')
    this.nameTextfieldTarget.focus()
  }

  closeForm () {
    this.bodyTarget.classList.add('hidden')
    this.openTarget.classList.remove('hidden')
    this.closeTarget.classList.add('hidden')
    this.headerTarget.classList.add('bg-gray-100')
    this.headerTarget.classList.remove('bg-blue-100')
  }

  submit (e) {
    if (this.nameTextfieldTarget.length > 0) {
      this.nameValueTarget.innerHTML = this.nameTextfieldTarget.value
    }
    Rails.fire(this.formTarget, 'submit')
  }

  selectFrequency (e) {
    this.specificDaysTarget.classList.add('hidden')
    this.daysPerWeekTarget.classList.add('hidden')
    this.daysPerMonthTarget.classList.add('hidden')
    this.specificDateTarget.classList.add('hidden')

    if (e.target.value === 'specific_days') {
      this.specificDaysTarget.classList.remove('hidden')
    } else if (e.target.value == 'days_per_week') {
      this.daysPerWeekTarget.classList.remove('hidden')
    } else if (e.target.value == 'days_per_month') {
      this.daysPerMonthTarget.classList.remove('hidden')
    } else if (e.target.value == 'specific_date') {
      this.specificDateTarget.classList.remove('hidden')
    }

    this.submit(e)
  }

  startEdit () {
    this.formTarget.classList.remove('hidden')
    this.viewTarget.classList.add('hidden')
    this.textfieldTarget.focus()
    this.value = this.nameTextfieldTarget.value
  }

  endEdit () {
    this.formTarget.classList.add('hidden')
    this.viewTarget.classList.remove('hidden')
  }

  cancelEdit () {
    this.nameTextfieldTarget.value = this.value
    this.endEdit()
  }

  deleteConfirm () {
    const that = this

    event.preventDefault()
    const url = this.deleteBtnTarget.dataset.url

    iziToast.question({
      timeout: 20000,
      close: false,
      overlay: true,
      displayMode: 'once',
      id: 'confirmGoalDelete',
      zindex: 9999,
      title: 'Confirm',
      message: 'Are you sure you want to delete this goal?',
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', function (instance, toast) {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          that.delete(event, url)
          return true
        }, true],
        ['<button>NO</button>', function (instance, toast) {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          return false
        }]
      ]
    })
  }

  delete (event, url) {
    const that = this
    Rails.ajax({
      type: 'delete',
      url: url,
      success: function (response) {
        that.deleteSuccess(event)
      },
      error: function (response) {
        iziToast.warning({
          title: 'Error',
          message: 'We encountered an error when deleting this goal.'
        })
      }
    })
  }

  deleteSuccess (event) {
    const goalId = event.target.dataset.goalId
    this.containerTarget.parentNode.remove()
    const goalListItem = document.body.querySelector('.goal-item[data-goal-item-id="' + goalId + '"]')
    if (goalListItem) {
      goalListItem.remove()
    }
  }

  close (event) {
  }
}
