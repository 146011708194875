import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class CommentsController extends Controller {
  static targets = ['form', 'body', 'submitBtn', 'content']

  connect () {
    // console.log('connect comments')
  }

  enable () {
    this.submitBtnTarget.classList.remove('hidden')
    this.bodyTarget.classList.remove('max-h-14')
  }

  disable () {
    this.submitBtnTarget.classList.add('hidden')
    this.bodyTarget.classList.add('max-h-14')
  }

  onPostSuccess (event) {
    const [data, status, xhr] = event.detail
    // console.log('onPostSuccess')
    // console.log(xhr.response)
    const response = JSON.parse(xhr.response)

    this.bodyTarget.value = ''
    this.contentTarget.insertAdjacentHTML('beforeend', response.comment_html)
    this.formTarget.reset
  }

  submit (e) {
    Rails.fire(this.formTarget, 'submit')
  }
}

