import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class LikesController extends Controller {
  static targets = ['form', 'likeBtn', 'reactions', 'content']

  connect () {
    this.storyId = this.data.get('storyId')
    this.url = this.data.get('url')
  }

  like (e) {
    const that = this
    const target = e.currentTarget
    const emoji = target.dataset.emoji
    const formData = new FormData()

    formData.append('story_id', this.storyId)
    formData.append('like[emoji]', emoji)

    Rails.ajax({
      url: this.url,
      type: 'POST',
      data: formData,
      dataType: 'json',
      accept: 'json',
      // credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      success: function (data) {
        console.log(data)
        that.contentTarget.innerHTML = data.html
      },
      error: function (data) {
        console.log(data)
      }
    })
  }

  showReactions () {
    this.reactionsTarget.classList.remove('hidden')

    let i = 1
    for (const icon of document.querySelectorAll('.reaction-icon')) {
      setTimeout(() => {
        icon.classList.add('show')
      }, 100 * i)
      i++
    }
  }

  hideReactions () {
    this.reactionsTarget.classList.add('hidden')
    for (const icon of document.querySelectorAll('.reaction-icon')) {
      icon.classList.remove('show')
    }
  }
}
