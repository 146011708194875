import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class VotesController extends Controller {
  static target = ['container']

  getUrl (target) {
    if (target.dataset.url) {
      return (target.dataset.url)
    } else {
      return (target.parentNode.dataset.url)
    }
  }

  submitVote (e) {
    const domId = this.data.get('domId')
    const url = this.getUrl(e.currentTarget)

    if (url) {
      fetch(url, {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        }
      })
        .then(response => response.json())
        .then(json => {
          document.getElementById(domId).innerHTML = json.votes
        })
    }
  }
}
