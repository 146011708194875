import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import morphdom from 'morphdom'

export default class BrainstormsController extends Controller {
  static targets = ['list', 'form', 'content', 'new']

  connect () {
    // console.log('connect brainstorms')
  }

  onPostSuccess (event) {
    const [data, status, xhr] = event.detail

    // console.log('onPostSuccess')
    // console.log(status)
    // console.log(xhr.response)
    //
    morphdom(this.formTarget, xhr.response)
  }

  submit (e) {
    if (e.target.value !== e.target.defaultValue) {
      // Only submit if value has changed
      Rails.fire(this.formTarget, 'submit')
    }
  }

  remove (e) {
    e.preventDefault()
    const target = e.currentTarget
    Rails.ajax({
      type: 'delete',
      url: target.dataset.url
    })

    e.currentTarget.parentNode.parentNode.remove()
  }

  add (e) {
    const newNode = this.newTarget.firstElementChild.cloneNode(true)
    this.contentTarget.append(newNode)
    const textfield = newNode.querySelectorAll('input[type=text]')[0]
    if (textfield) {
      textfield.focus()
    }
  }
}
