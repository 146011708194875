import { Controller } from 'stimulus'

export default class FamilyPlanController extends Controller {
  static targets = ['imagePicker', 'editFamily', 'familyName']

  connect () {
    // console.log('hello from family plan')
  }

  getModalTarget (e) {
    const target = e.target.dataset.modalTarget
    if (target == 'imagePicker') {
      return this.imagePickerTarget
    }
    if (target == 'editFamily') {
      return this.editFamilyTarget
    }
  }

  openModal (e) {
    const modalTarget = this.getModalTarget(e)
    const updateUrl = e.currentTarget.dataset.updateUrl
    const imageElementId = e.currentTarget.dataset.imageElementId
    const imageClassName = e.currentTarget.dataset.imageClassName

    const modalController = this.application.getControllerForElementAndIdentifier(
      modalTarget,
      'modal'
    )
    modalController.setUpdateUrl(updateUrl)
    modalController.setImageElementId(imageElementId)
    modalController.setImageClassName(imageClassName)
    modalController.open(e)
  }

  onChangeNameSuccess (event) {
    const [data, status, xhr] = event.detail

    const response = JSON.parse(xhr.response)

    this.familyNameTarget.innerHTML = response.name
  }

  onChangeNameError (event) {
    const [data, status, xhr] = event.detail

    const response = JSON.parse(xhr.response)
    if (response.name) {
      iziToast.warning({
        title: 'Error',
        message: 'Error updating family name, it ' + response.name
      })
    } else {
      iziToast.warning({
        title: 'Error',
        message: 'Error updating your family name'
      })
    }
  }
}
