import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class GoalsController extends Controller {
  static targets = ['container', 'addBtn']

  connect () {
    // console.log('rituals connected')
  }

  onCreateSuccess (event) {
    const [data, status, xhr] = event.detail
    // console.log('onCreateSuccess')
    // console.log(status)
    // console.log(xhr.response)
    const response = JSON.parse(xhr.response)
    // console.log(response.list_item)

    // this.containerTarget.insertAdjacentHTML('beforeend', response.)
    document.getElementById('goals-list').insertAdjacentHTML('beforeend', response.list_item)
  }

  getMetaValue (name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute('content')
  }

  add (e) {
    const target = e.currentTarget
    const url = target.dataset.url
    const goalTargetId = target.dataset.goalTargetId
    const goalListTargetId = target.dataset.goalListTargetId
    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      }
    })
      .then(response => response.json())
      .then(json => {
        // console.log(json.list_item)
        var e = document.createElement('div')
        e.innerHTML = json.goal_html

        const goalElement = document.getElementById(goalTargetId)
        goalElement.append(e)
        const textfield = e.querySelectorAll('input[type=text]')[0]
        if (textfield) {
          textfield.focus()
        }

        const goalList = document.getElementById(goalListTargetId)
        if (goalList) {
          goalList.insertAdjacentHTML('beforeend', json.list_item)
        }
      })
  }
}
