import { Controller } from 'stimulus'
import Dropzone from 'dropzone'
import flatpickr from 'flatpickr'
import moment from 'moment'
import Rails from '@rails/ujs'

export default class StoryController extends Controller {
  static targets = ['form', 'storyType', 'journalType',
    'journalOptions', 'recognitionOptions', 'personalOptions',
    'familyOptions', 'body', 'storyline', 'dropzone']

  connect () {
    // console.log('day connect')
    // this.id = this.data.get('id')
    // this.url = this.data.get('url')
    // this.dayItems = document.querySelectorAll(`[data-day-item-id='${this.id}']`)
  }

  selectStoryType (e) {
    const storyType = e.currentTarget.dataset.storyType

    this.storyTypeTargets.forEach((el, i) => {
      console.log(el.value)
      if (storyType == el.value) {
        // el.checked = true
        el.click()
      }
    })
  }

  toggleStoryType (e) {
    this.formTarget.reset()
    const storyType = e.currentTarget.value

    this.journalOptionsTarget.classList.add('hidden')
    this.recognitionOptionsTarget.classList.add('hidden')
    this.familyOptionsTarget.classList.add('hidden')
    this.personalOptionsTarget.classList.add('hidden')
    this.bodyTarget.classList.remove('hidden')

    if (storyType === 'journal') {
      this.storyTypeTargets[0].checked = true
      this.journalOptionsTarget.classList.remove('hidden')
    } else if (storyType === 'recognition') {
      this.storyTypeTargets[1].checked = true
      this.recognitionOptionsTarget.classList.remove('hidden')
    } else if (storyType === 'photo') {
      this.storyTypeTargets[2].checked = true
      this.bodyTarget.classList.add('hidden')
    }
  }

  toggleJournalType (e) {
    const journalType = e.currentTarget.value

    this.familyOptionsTarget.classList.add('hidden')
    this.personalOptionsTarget.classList.add('hidden')

    if (journalType === 'family') {
      this.familyOptionsTarget.classList.remove('hidden')
    } else if (journalType === 'personal') {
      this.personalOptionsTarget.classList.remove('hidden')
    }
  }

  submit (e) {
    Rails.fire(this.formTarget, 'submit')
  }

  onPostError (event) {
    const [data, status, xhr] = event.detail
    console.log(xhr.response)
    const response = JSON.parse(xhr.response)
    let errorMsg = 'Sorry, unable to save this story'
    if (response.errors) {
      errorMsg = response.errors
    }

    iziToast.warning({
      title: 'Error',
      position: 'center',
      message: errorMsg
    })
  }

  onPostSuccess (event) {
    const [data, status, xhr] = event.detail

    const response = JSON.parse(xhr.response)

    // console.log(response)
    this.storylineTarget.insertAdjacentHTML('afterbegin', response.story_html)
    this.formTarget.reset()
    const dz = Dropzone.forElement(this.dropzoneTarget)
    dz.removeAllFiles(true)

    document.querySelector('#add-story').modal.close()
  }
}
