import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'
import moment from 'moment'
import Rails from '@rails/ujs'

export default class DayController extends Controller {
  static targets = ['form', 'deleteBtn']

  connect () {
    // console.log('day connect')
    this.id = this.data.get('id')
    this.url = this.data.get('url')
    this.dayItems = document.querySelectorAll(`[data-day-item-id='${this.id}']`)
  }

  onPostSuccess (event) {
    const [data, status, xhr] = event.detail
    // console.log('onPostSuccess')
    // console.log(xhr.response)

    for (var i = 0; i < this.dayItems.length; i++) {
      const dayItem = this.dayItems[i]
      dayItem.innerHTML = xhr.response
    }
  }

  submit (e) {
    Rails.fire(this.formTarget, 'submit')
  }

  deleteConfirm () {
    const that = this

    event.preventDefault()
    const url = this.deleteBtnTarget.dataset.url

    iziToast.question({
      timeout: 20000,
      close: false,
      overlay: true,
      displayMode: 'once',
      id: 'confirmDayDelete',
      zindex: 9999,
      title: 'Confirm',
      message: 'Are you sure you want to delete this day?',
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', function (instance, toast) {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          that.delete(event, url)
          return true
        }, true],
        ['<button>NO</button>', function (instance, toast) {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          return false
        }]
      ]
    })
  }

  delete (event, url) {
    const that = this
    Rails.ajax({
      type: 'delete',
      url: url,
      success: function (response) {
        that.deleteSuccess(event)
      },
      error: function (response) {
        iziToast.warning({
          title: 'Error',
          message: 'We encountered an error when deleting this day.'
        })
      }
    })
  }

  deleteSuccess (event) {
    this.element.parentNode.removeChild(this.element)
    for (var i = 0; i < this.dayItems.length; i++) {
      const dayItem = this.dayItems[i]
      // console.log(dayItem)
      dayItem.remove()
    }
  }
}
