import { Controller } from 'stimulus'
import Velocity from 'velocity-animate'

export default class NavbarController extends Controller {
  static targets = ['navbar', 'mobile']

  connect () {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  toggleMobile () {
    console.log('toggle menu')
    this.mobileTarget.classList.toggle(this.toggleClass)
  }

  hide (event) {
    if (
      this.element.contains(event.target) === false &&
      !this.menuTarget.classList.contains(this.toggleClass)
    ) {
      this.menuTarget.classList.add(this.toggleClass)
    }
  }

  updateBackground (event) {
    if (window.pageYOffset >= 70) {
      this.navbarTarget.classList.remove('top-12')
      /*
      const navbar = this.navbarTarget
      Velocity(this.navbarTarget, { top: 0 }, {
        delay: 10,
        speed: 1.5,
        complete: function (elements, activeCall) {
          elements[0].classList.remove('top-12')
        }
      })
      */
      // this.navbarTarget.classList.remove('top-12')
      this.navbarTarget.classList.add('bg-blue-600')
      // this.navbarTarget.classList.add('top-0')
    } else {
      /*
      Velocity(this.navbarTarget, { top: '3rem' }, {
        delay: 10,
        speed: 1.5,
        complete: function (elements, activeCall) {
          elements[0].classList.remove('top-0')
          elements[0].classList.add('top-12')
          // navbar.classList.add('top-12')
        }
      }) */
      // this.navbarTarget.classList.remove('top-0')
      this.navbarTarget.classList.add('top-12')
      this.navbarTarget.classList.remove('bg-blue-600')
      // this.navbarTarget.classList.remove('top-0')
    }
  }
}
