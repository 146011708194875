import { Controller } from 'stimulus'

export default class DropdownController extends Controller {
  static targets = ['menu']

  connect () {
    // console.log('connect dropdown')
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  toggle () {
    // console.log('toggle menu')
    this.menuTarget.classList.toggle(this.toggleClass)
  }

  hide (event) {
    if (
      this.element.contains(event.target) === false &&
      !this.menuTarget.classList.contains(this.toggleClass)
    ) {
      this.menuTarget.classList.add(this.toggleClass)
    }
  }
}
