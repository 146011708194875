import { Controller } from 'stimulus'
// import StimulusReflex from 'stimulus_reflex'

export default class MissionController extends Controller {
  static targets = ['content']

  connect () {
    // StimulusReflex.register(this)
  }

  onPostSuccess (event) {
    const [data, status, xhr] = event.detail
    console.log(xhr.response)
    this.contentTarget.innerHTML = xhr.response
  }
}
