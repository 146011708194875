import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'

export default class UppyController extends Controller {
  static targets = ['form', 'content', 'trigger', 'imagePreview']

  connect () {
  }

  initialize () {
    // const trigger = triggerTarget // element.querySelector('[data-behavior="uppy-trigger"]')
    const direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute('content')
    const field_name = this.data.get('target-id')

    this.triggerTarget.addEventListener('click', (event) => event.preventDefault())

    const uppy = Uppy({
      autoProceed: true,
      allowMultipleUploads: false,
      logger: Uppy.debugLogger,
      companionHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
      }
    })

    uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url
    })

    uppy.use(Dashboard, {
      trigger: this.triggerTarget,
      closeAfterFinish: true
    })

    uppy.on('complete', (result) => {
      this.contentTarget.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))

      result.successful.forEach(file => {
        this.appendUploadedFile(file, field_name)
        this.setPreview(file)
      })

      Rails.fire(this.formTarget, 'submit')

      uppy.reset()
    })
  }

  appendUploadedFile (file, field_name) {
    const hiddenField = document.createElement('input')

    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', field_name)
    hiddenField.setAttribute('data-pending-upload', true)
    hiddenField.setAttribute('value', file.response.signed_id)

    this.formTarget.appendChild(hiddenField)
  }

  setPreview (file) {
    if (this.imagePreviewTarget) {
      const src = (file.preview) ? file.preview : 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY'
      this.imagePreviewTarget.src = src
    }
  }
}
