import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class RemoteFormController extends Controller {
  static targets = ['content', 'form']

  submit (e) {
    // console.log('submit')
    Rails.fire(this.formTarget, 'submit')
  }

  onPostSuccess (event) {
    const [data, status, xhr] = event.detail

    const contentId = this.data.get('content-id')
    const content = document.getElementById(contentId)

    // console.log(xhr.response)
    // this.contentTarget.innerHTML = xhr.response
    content.innerHTML = xhr.response
  }
}
