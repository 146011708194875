import { Controller } from 'stimulus'

export default class OnboardingController extends Controller {
  static targets = ['missionExamples', 'valuesExamples', 'ritualsExamples',
    'goalsExamples', 'imagePicker', 'newMemberForm', 'memberFormContent']

  getModalTarget (e) {
    const target = e.currentTarget.dataset.modalTarget
    if (target === 'missionExamples') {
      return this.missionExamplesTarget
    } else if (target === 'valuesExamples') {
      return this.valuesExamplesTarget
    } else if (target === 'ritualsExamples') {
      return this.ritualsExamplesTarget
    } else if (target === 'goalsExamples') {
      return this.goalsExamplesTarget
    } else if (target === 'imagePicker') {
      return this.imagePickerTarget
    }
  }

  openModal (e) {
    const modalTarget = this.getModalTarget(e)
    const updateUrl = e.currentTarget.dataset.updateUrl
    const imageElementId = e.currentTarget.dataset.imageElementId

    const modalController = this.application.getControllerForElementAndIdentifier(
      modalTarget,
      'modal'
    )
    modalController.setUpdateUrl(updateUrl)
    modalController.setImageElementId(imageElementId)
    modalController.open(e)
  }

  addMember (e) {
    const newNode = this.newMemberFormTarget.firstElementChild.cloneNode(true)
    this.memberFormContentTarget.append(newNode)
    const firstName = newNode.querySelectorAll('input[type=text]')[0]
    if (firstName) {
      firstName.focus()
    }
  }

  removeMember (e) {
    const target = e.currentTarget
    e.currentTarget.parentNode.parentNode.remove()
  }
}
