import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class ImagePickerController extends Controller {
  static targets = ['scrollview', 'entries', 'pagination', 'searchField']

  connect () {
    // console.log('connect image picker')
    this.loadContent(this.data.get('url'))
    this.searchFieldTarget.value = ''
  }

  select (e) {
    const imageId = e.currentTarget.dataset.imageId
    // console.log(imageId)
  }

  resetSearch () {
    this.searchFieldTarget.value = ''
    this.loadContent(this.data.get('url'))
  }

  loadContent (url) {
    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: 'html',
      success: (document) => {
        this.scrollviewTarget.innerHTML = document.body.innerHTML
      }
    })
  }

  scroll () {
    const contentHeight = this.scrollviewTarget.scrollHeight -
      this.scrollviewTarget.offsetHeight - 30
    const scrollTop = this.scrollviewTarget.scrollTop

    if (contentHeight <= scrollTop) {
      this.loadMore()
    }
  }

  loadMore () {
    const next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }
    const url = next_page.href

    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
      }
    })
  }

  search () {
    let url = this.data.get('url')
    if (this.searchFieldTarget.value.length > 0) {
      url = url + '?search=' + this.searchFieldTarget.value
    }

    this.loadContent(url)
  }
}
