import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class RitualsController extends Controller {
  static targets = ['container', 'form', 'content', 'new', 'addBtn']

  connect () {
    // console.log('rituals connected')
  }

  onPostSuccess (event) {
    const [data, status, xhr] = event.detail
    // console.log('onPostSuccess')
    // console.log(status)
    // console.log(xhr.response)
    //
  }

  onCreateSuccess (event) {
    const [data, status, xhr] = event.detail
    // console.log('onCreateSuccess')
    // console.log(status)
    // console.log(xhr.response)
    const response = JSON.parse(xhr.response)
    // console.log(response.list_item)

    this.contentTarget.insertAdjacentHTML('beforeend', response.ritual)
    const ritualsList = document.getElementById('rituals-list')
    if (ritualsList) {
      document.getElementById('rituals-list').insertAdjacentHTML('beforeend', response.list_item)
    }
  }

  submit (e) {
    if (e.target.value !== e.target.defaultValue) {
      Rails.fire(this.formTarget, 'submit')
    }
  }

  deleteConfirm (event) {
    const that = this

    event.preventDefault()
    const target = event.currentTarget
    const url = target.dataset.url

    iziToast.question({
      timeout: 20000,
      close: false,
      overlay: true,
      displayMode: 'once',
      id: 'confirmRitualDelete',
      zindex: 9999,
      title: 'Confirm',
      message: 'Are you sure you want to delete this ritual?',
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', function (instance, toast) {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          that.delete(target, url)
          return true
        }, true],
        ['<button>NO</button>', function (instance, toast) {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          return false
        }]
      ]
    })
  }

  delete (target, url) {
    const currentTarget = target
    Rails.ajax({
      type: 'delete',
      url: url,
      success: function (response) {
        currentTarget.parentNode.parentNode.remove()
      },
      error: function (response) {
        iziToast.warning({
          title: 'Error',
          message: 'We encountered an error when deleting this ritual.'
        })
      }
    })
  }

  add (e) {
    const that = this
    const url = this.addBtnTarget.dataset.url
    Rails.ajax({
      type: 'post',
      url: url,
      success: function (response) {
        const ritualsList = document.getElementById('rituals-list')
        if (ritualsList) {
          document.getElementById('rituals-list').insertAdjacentHTML('beforeend', response.list_item)
        }
        that.contentTarget.insertAdjacentHTML('beforeend', response.ritual)
        /*
        const textfield = newNode.querySelectorAll('input[type=text]')[0]
        if (textfield) {
          textfield.focus()
        }
        */
      },
      error: function (response) {
        iziToast.warning({
          title: 'Error',
          message: 'We encountered an error when adding a new day.'
        })
      }
    })
  }
}
