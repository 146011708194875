import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class ModalController extends Controller {
  static targets = ['container', 'inset', 'background', 'modal', 'link']

  connect () {
    this.element[this.identifier] = this
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  disconnect () {
    this.close()
  }

  reveal () {
    this.linkTarget.classList.remove('hidden')
  }

  hide () {
    this.linkTarget.classList.add('hidden')
  }

  setUpdateUrl (updateUrl) {
    this.updateUrl = updateUrl
  }

  setImageClassName (imageClassName) {
    this.imageClassName = imageClassName
  }

  setImageElementId (imageElementId) {
    this.imageElementId = imageElementId
  }

  selectImage (e) {
    this.imageId = e.currentTarget.dataset.imageId
    if (this.selectedImage) {
      this.selectedImage.classList.remove('bg-green-200')
      this.selectedImage.classList.add('hover:bg-blue-100')
    }
    e.currentTarget.classList.remove('hover:bg-blue-100')
    e.currentTarget.classList.add('bg-green-200')
    this.selectedImage = e.currentTarget
  }

  updateImage (e) {
    const imageElementId = this.imageElementId
    const imageClassName = this.imageClassName
    const selectedImage = this.selectedImage.firstElementChild

    const formData = new FormData()

    if (this.updateUrl.includes('mission')) {
      formData.append('mission[image_id]', this.imageId)
    } else if (this.updateUrl.includes('value')) {
      formData.append('value[image_id]', this.imageId)
    }

    Rails.ajax({
      type: 'PATCH',
      url: this.updateUrl,
      data: formData,
      contentType: 'json',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      success: function () {
        if (imageElementId) {
          const image = document.getElementById(imageElementId)
          image.src = selectedImage.src
        } else if (imageClassName) {
          const images = document.getElementsByClassName(imageClassName)
          for (let i = 0; i < images.length; i++) {
            images[i].src = selectedImage.src
          }
        }
      }
    })
    this.close(e)
  }

  open (e) {
    e.preventDefault()
    e.target.blur()

    // Lock the scroll and save current scroll position
    this.lockScroll()

    // Unhide the modal
    this.containerTarget.classList.remove(this.toggleClass)
    this.insetTarget.classList.remove(this.toggleClass)
    this.modalTarget.classList.remove(this.toggleClass)

    this.containerTarget.classList.add('fixed')
    this.insetTarget.classList.add('fixed')
  }

  close (e) {
    if (e) e.preventDefault()

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll()

    // Hide the modal
    this.containerTarget.classList.remove('fixed')
    this.insetTarget.classList.remove('fixed')

    this.containerTarget.classList.add(this.toggleClass)
    this.insetTarget.classList.add(this.toggleClass)
    this.modalTarget.classList.add(this.toggleClass)
  }

  closeBackground (e) {
    if (e.target === this.backgroundTarget) {
      console.log('close')
      this.close(e)
    }
  }

  closeWithKeyboard (e) {
    if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
      this.close(e)
    }
  }

  lockScroll () {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`

    // Save the scroll position
    this.saveScrollPosition()

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden')

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`
  }

  unlockScroll () {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden')

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition()

    // Remove the negative top inline style from body
    document.body.style.top = null
  }

  saveScrollPosition () {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop
  }

  restoreScrollPosition () {
    document.documentElement.scrollTop = this.scrollPosition
  }
}
